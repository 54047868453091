<template>
  <div class="breadcrumb">
    <a-breadcrumb>
      <a-breadcrumb-item>{{ catalogueMeta }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ currentMeta }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
export default {
  created() {},
  computed: {
    catalogueMeta() {
      return this.$route.matched[0].meta.title;
    },
    currentMeta() {
      return this.$route.meta.title;
    },
  },
};
</script>

<style scoped lang="less">
.breadcrumb {
  width: 100%;
  height: 54px;
  background: #ffffff;
  padding: 17px 24px;
  box-sizing: border-box;
}
</style>
