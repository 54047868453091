<template>
  <div class="layout-content d-f f-d">
    <slot name="breadcrumb"></slot>
    <div class="list-container">
      <div class="list">
        <div class="title d-f j-b">
          <div>
            {{ currentMeta }} <span v-if="total">（{{ total }}个）</span>
          </div>
          <slot name="back"></slot>
        </div>
        <div class="space">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayOutContent",
  props: ["total"],
  data() {
    return {};
  },
  computed: {
    currentMeta() {
      return this.$route.meta.title;
    },
  },
};
</script>

<style scoped lang="less">
.layout-content {
  width: 100%;
  height: 100%;
  .list-container {
    flex: 1;
    padding: 24px;
    .list {
      width: 100%;
      height: 100%;
      background: #ffffff;
      .title {
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #e9e9e9;
        padding: 24px 32px;
        box-sizing: border-box;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
      .space {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
